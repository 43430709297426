import React from "react";
import { css } from "goober";
import type { ElementOf } from "ts-essentials";

import type { Meta } from "@/__main__/router.mjs";
import { SEARCH_PARAMS } from "@/feature-news/constants.mjs";
import type { ArticleLinksProps } from "@/marketing/ArticleLinks.jsx";
import ArticleLinks from "@/marketing/ArticleLinks.jsx";
import ArticleTile from "@/marketing/ArticleTile.jsx";
import Container from "@/shared/ContentContainer.jsx";

const items: Array<{
  text: string;
  subtitle: string;
  links: ArticleLinksProps["links"];
}> = [
  {
    text: "News Manager",
    subtitle:
      "View, manage and deploy changes to staging and production articles",
    links: [
      {
        text: "Manage production articles",
        href: "/cms/news-manager",
      },
      {
        text: "Manage staging articles",
        href: `/cms/news-manager?${SEARCH_PARAMS.ENV}=staging`,
      },
    ],
  },
].map((i) => {
  i.links.forEach(
    (j: ElementOf<ArticleLinksProps["links"]>) => (j.preventTracking = true),
  );
  return i;
});

export default function Homepage(): React.JSX.Element {
  return (
    <Container className={cssHomepageGrid()}>
      {items.map((i) => (
        <ArticleTile
          key={i.text as string}
          title={i.text}
          subtitle={i.subtitle}
        >
          <ArticleLinks links={i.links} />
        </ArticleTile>
      ))}
    </Container>
  );
}

export function meta(): Meta {
  return {
    title: ["", ""],
    description: ["", ""],
  };
}

const cssHomepageGrid = () => css`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--sp-2);
`;
